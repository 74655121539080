<template>
  <div>
    <div class="container" v-if="objectInfo">
      <div class="row travehicle-object-info">
        <span>所在位置：{{ mapAddress }}</span>
        <span><a-button @click="setMapAddress">获取位置信息</a-button></span>
      </div>
      <div class="row">
        <span>设备编号：</span>
        <span id="deviceId" v-if="source === 'map'" />
        <span v-else>{{ objectInfo.deviceId }} </span>
      </div>
      <div class="row">
        <span>车辆自编号：</span>
        <span id="vehicleNo" v-if="source === 'map'" />
        <span v-else>{{ objectInfo.bindKey }} </span>
      </div>
      <div class="row">
        <span>车牌号：</span>
        <span id="plateNo" v-if="source === 'map'" />
        <span v-else>{{ objectInfo.bindName }} </span>
      </div>
      <div class="row">
        <span>SimNo：</span>
        <span id="simNo" v-if="source === 'map'" />
        <span v-else>{{ objectInfo.simNo }} </span>
      </div>
      <div class="row">
        <span>速度：</span>
        <span id="speed" v-if="source === 'map'" />
        <span v-else>{{ objectInfo.speed ? Number(objectInfo.speed).toFixed(2) + 'KM/H' : '暂无速度' }} </span>
      </div>
      <div class="row">
        <span>方向：</span>
        <span class="direction">
          <span id="direction" v-if="source === 'map'" />
          <span v-else>{{ vehicleRotation }} </span> °
          <img id="imgtMap" :src="arrowUrl" v-if="source === 'map'" />
          <img v-else id="imgtInfo" :src="arrowUrl" />
        </span>
      </div>
      <div class="row">
        <span>定位时间：</span>
        <span id="deviceTime" v-if="source === 'map'" />
        <span v-else>{{ objectInfo.deviceTime }} </span>
      </div>
      <div class="row">
        <span>在线状态：</span>
        <span id="status" v-if="source === 'map'" />
        <span v-else>{{ objectInfo.online ? '在线' : '离线' }} </span>
      </div>
    </div>
    <footer-tool ref="footerRef" v-if="showFooter" :objectInfo="footerObj" />
  </div>
</template>
<script>
import get from 'lodash/get'
import FooterTool from '../../../footerTool'
import MonitorBase from '@/views/monitor/monitorBase'
export default {
  name: 'MapTraditionalVehicleInfoWindow',
  components: { FooterTool },
  mixins: [MonitorBase],
  props: {
    objectInfo: {
      type: Object,
      default: () => {}
    },
    showFooter: {
      type: Boolean,
      default: true
    },
    mapObj: {
      type: Object,
      default: () => {}
    },
    source: {
      type: String,
      default: ''
    }
  },
  watch: {
    objectInfo(newVal, oldVal) {
      if (newVal) {
        this.footerObj.deviceId = newVal.deviceId
        this.footerObj.deviceTime = newVal.deviceTime
      }
    }
  },
  computed: {
    vehicleRotation() {
      const rotation = Number(get(this.objectInfo, 'rotation', 0))
      this.$nextTick(() => {
        const el = document.getElementById('imgtInfo')
        this.setArrow(el, rotation)
      })
      return rotation
    }
  },
  data() {
    return {
      mapAddress: '',
      footerObj: {}
    }
  },
  mounted() {},
  methods: {
    setFormData(form) {
      if (!form) {
        return
      }
      this.lng = form.lng
      this.lat = form.lat
      this.footerObj.deviceId = form.deviceId
      this.footerObj.deviceTime = form.deviceTime

      this.setSpanValue('deviceId', form.deviceId)
      this.setSpanValue('vehicleNo', form.bindKey ? form.bindKey : '')
      this.setSpanValue('plateNo', form.bindName)
      this.setSpanValue('simNo', form.simNo)
      // 速度
      const speed = form.speed != null ? Number(form.speed).toFixed(2) : 0
      this.setSpanValue('speed', speed + 'KM/H')
      this.setSpanValue('deviceTime', form.deviceTime)
      this.setSpanValue('status', form.online ? '在线' : '离线')

      // 方向
      const rotation = Number(get(form, 'rotation', 0))
      const el = document.getElementById('imgtMap')
      this.setArrow(el, rotation)

      this.setSpanValue('direction', rotation ? rotation.toFixed(2) : rotation)
      this.$refs.footerRef.setData({ deviceId: form.deviceId, deviceTime: form.deviceTime })
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 13px;
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    span:first-child {
      min-width: 70px;
    }
    img {
      width: 25px;
      height: 25px;
      min-width: 25px;
    }
    .direction {
      display: flex;
      align-items: center;
      span:first-child {
        min-width: 0;
      }
    }
  }
}
</style>
<style lang="less">
.travehicle-object-info {
  justify-content: space-between;
  // height: 40px;
  span:first-child {
    padding-right: 3px;
  }
  .ant-btn {
    height: 22px !important;
    border-radius: 1px !important;
    padding-left: 5px;
    padding-right: 5px;
  }
}
</style>
